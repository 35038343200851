<template>
  <div class="text-light">
    <topnav></topnav>
    <div
      class="main main-raised"
      style="margin: 0px; background-color: #1c212e; min-height: 100vh"
    >
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        :is-full-page="fullPage"
        :height="height"
        :width="width"
        background-color="#6b6969"
      ></loading>
      <div class="section mt-5">
        <div class="custom-container-fluid">
          <div class="row">
            <div class="col-6 col-md-2 offset-md-10">
              <selectize v-on:change="search()" :settings="settings">
                <!-- settings is optional -->
                <option value selected>Search a movie or t.v. series...</option>
              </selectize>
            </div>
          </div>
          <div class="row mt-3 mb-5">
            <div class="col-md-10">
              <div
                id="carouselExampleIndicators"
                class="carousel slide"
                data-interval="false"
              >
                <ol class="carousel-indicators d-block d-sm-none text-center">
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="0"
                    class="active"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="1"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="2"
                  ></li>
                </ol>
                <div class="carousel-inner">
                  <div
                    :class="['carousel-item', i == 0 ? 'active' : '']"
                    v-for="(a, i) in HeaderMovies"
                    :key="i"
                  >
                    <div class="tainer">
                      <img
                        class="d-block w-100 slider_image slider_image"
                        v-lazy="a.image"
                        style="
                          height: 700px;
                          object-fit: cover;
                          border-radius: 5px;
                        "
                        alt="First slide"
                      />
                    </div>
                    <div class="carousel-caption">
                      <div class="nivo-caption mb-3">
                        <h3 class="font-weight-bold m-0">{{ a.post_title }}</h3>
                        <div class="caption_text mb-3 font-weight-bold">
                          <span v-if="a.movie_time_duration"
                            >{{ a.movie_time_duration }} |</span
                          >
                          <span v-for="(aa, k) in a.categories" :key="k">
                            {{ aa }}
                            <span v-if="a.categories.length - 1 !== k">,</span>
                          </span>
                        </div>
                        <p class="d-none d-sm-block font-weight-bold">
                          {{ a.post_excerpt }}
                        </p>
                        <div v-show="a.post_type == 'movie'">
                          <router-link
                            :to="'/movie-details/' + a.id"
                            class="btn btn-atvyellow pl-5 pr-5"
                            @click.native.prevent="
                              handleNavigation(a.id, 'moviedetails')
                            "
                            >Details</router-link
                          >
                        </div>

                        <div v-show="a.post_type == 'tv_show'">
                          <router-link
                            :to="'/series-details/' + a.id"
                            class="btn btn-atvyellow pl-5 pr-5"
                            @click.native.prevent="
                              handleNavigation(a.id, 'seriesdetails')
                            "
                            >Details</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2 d-none d-sm-block-custom d-sm-none d-md-block">
              <img
                data-target="#carouselExampleIndicators"
                :data-slide-to="i"
                v-for="(a, i) in HeaderMovies.slice(0, 3)"
                :key="i"
                v-lazy="a.image"
                alt
                style="border-radius: 5px; height: 215px"
                class="img-fluid mb-4 w-100 cursor-pointer"
              />
            </div>
          </div>

          <!--newly added content section-->
          <div class="row">
            <div class="col-md-6">
              <h3 class="text-atvyellow font-weight-bold">
                Newly Added Content
              </h3>
              <p>Newly added content keeps coming and coming!</p>
            </div>
            <div class="col-md-6">
              <!--<h5 class="mr-md-3 text-md-right text-muted font-weight-bold">View all</h5>-->
            </div>
          </div>
          <div class="row owl-carousel pl-3">
            <div
              class="mr-md-5 mr-3"
              v-for="(a, i) in NewlyAddedContent"
              :key="i"
            >
              <div v-show="a.post_type == 'movie'">
                <router-link
                  :to="'/movie-details/' + a.id"
                  @click.native.prevent="handleNavigation(a.id, 'moviedetails')"
                >
                  <div class="card cursor-pointer">
                    <img v-lazy="a.image" class="card-img-top" alt />
                  </div>
                  <div class="mt-1 movie-text"></div>
                  <div class="font-weight-bold">{{ a.post_title }}</div>
                </router-link>
              </div>

              <div v-show="a.post_type == 'tv_show'">
                <router-link
                  :to="'/series-details/' + a.id"
                  @click.native.prevent="
                    handleNavigation(a.id, 'seriesdetails')
                  "
                >
                  <div class="card cursor-pointer">
                    <img v-lazy="a.image" class="card-img-top" alt />
                  </div>
                  <div class="mt-1 movie-text"></div>
                  <div class="font-weight-bold">{{ a.post_title }}</div>
                </router-link>
              </div>
            </div>
          </div>
          <!--<div class="space-top"></div>-->

          <!--LIVE TV-->
          <div class="row">
            <div class="col-md-6">
              <h3 class="text-atvyellow font-weight-bold">Live TV</h3>
              <p>Watch the best in Live TV streaming now!</p>
            </div>
            <div class="col-md-6">
              <!--<h5 class="mr-md-3 text-md-right text-muted font-weight-bold">View all</h5>-->
            </div>
          </div>
          <div class="row newest-episode pl-3" v-if="NewestEpisodes.length > 0">
            <div
              class="mr-md-5 mr-3"
              v-for="(a, i) in NewestEpisodes[0].episodes"
              :key="i"
              @click="
                movie_title = a.title;
                poster_image = a.image;
                showVideo(a.video_url, a.type);
              "
            >
              <div class="card cursor-pointer">
                <img
                  v-lazy="a.image"
                  class="card-img-top newest-episode-card"
                  alt
                />
              </div>
              <div class="mt-1 movie-text"></div>
              <div class="font-weight-bold">{{ a.title }}</div>
            </div>
          </div>
          <!--END OF LIVE TV-->
          <div class="space-top"></div>

          <!--newest section-->
          <div class="row">
            <div class="col-md-6">
              <h3 class="text-atvyellow font-weight-bold">Newest Movies</h3>
              <p>The newest movies released to the Diaspora.</p>
            </div>
            <div class="col-md-6">
              <!--<h5 class="mr-md-3 text-md-right text-muted font-weight-bold">View all</h5>-->
            </div>
          </div>
          <div class="row newest-movies pl-3">
            <div class="mr-md-5 mr-3" v-for="(a, i) in NewestMovies" :key="i">
              <router-link
                :to="'/movie-details/' + a.id"
                @click.native.prevent="handleNavigation(a.id, 'moviedetails')"
              >
                <div class="card cursor-pointer">
                  <img v-lazy="a.image" class="card-img-top" alt />
                </div>
                <div class="mt-1 movie-text">
                  <!-- <span v-if="a.movireleasedate">{{ getYear(a.movireleasedate) }},</span>
                  <span v-for="(aa, k) in a.categories.slice(0, 1)" :key="k">
                    {{ aa
                    }}
                    <span v-if="a.categories.slice(0, 1).length - 1 !== k">,</span>
                  </span>
                  <span v-if="a.categories.length > 1">...</span>-->
                </div>
                <div class="font-weight-bold">{{ a.post_title }}</div>
              </router-link>
            </div>
          </div>
          <!--<div class="space-top"></div>-->

          <!--The Best Series & TV Shows-->
          <div class="row">
            <div class="col-md-6">
              <h3 class="text-atvyellow font-weight-bold">
                The Best Series & TV Shows
              </h3>
            </div>
            <div class="col-md-6">
              <!--<h5 class="mr-md-3 text-md-right text-muted font-weight-bold">View all</h5>-->
            </div>
          </div>
          <div class="best-series-owl-carousel">
            <div class="row">
              <div
                class="col-md-4 wide_space"
                v-for="(a, i) in Besttvshows.slice(0, 1)"
                :key="i"
              >
                <router-link
                  :to="'/series-details/' + a.id"
                  @click.native.prevent="
                    handleNavigation(a.id, 'seriesdetails')
                  "
                >
                  <img
                    v-lazy="a.image"
                    alt
                    class="img-fluid w-100 best-series-first-img"
                    style="
                      border-radius: 10px;
                      height: 500px;
                      object-fit: cover;
                      margin-top: 15px;
                    "
                  />
                </router-link>
              </div>
              <div class="col-md-8">
                <div class="row mt-md-0 mt-4">
                  <div
                    class="col-md-4 col-sm-6 col-xs-6 col-6 wide_space"
                    v-for="(a, i) in Besttvshows.slice(1, 7)"
                    :key="i"
                  >
                    <router-link
                      :to="'/series-details/' + a.id"
                      @click.native.prevent="
                        handleNavigation(a.id, 'seriesdetails')
                      "
                    >
                      <div class="card cursor-pointer best-series-card">
                        <img
                          v-lazy="a.image"
                          class="card-img-top cust-img-top"
                          alt
                        />
                      </div>
                      <div class="mt-1 movie-text">
                        <!-- <span v-if="a.movireleasedate">{{ getYear(a.movireleasedate) }},</span>
                        <span v-for="(aa, k) in a.categories.slice(0, 4)" :key="k">
                          {{ aa
                          }}
                          <span v-if="a.categories.slice(0, 4).length - 1 !== k">,</span>
                        </span>
                        <span v-if="a.categories.length > 4">...</span>-->
                      </div>
                      <div class="font-weight-bold">{{ a.post_title }}</div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="Besttvshows.length > 7">
              <div
                class="col-md-4 wide_space"
                v-for="(a, i) in Besttvshows.slice(7, 8)"
                :key="i"
              >
                <router-link
                  :to="'/series-details/' + a.id"
                  @click.native.prevent="
                    handleNavigation(a.id, 'seriesdetails')
                  "
                >
                  <img
                    v-lazy="a.image"
                    alt
                    class="img-fluid w-100"
                    style="
                      border-radius: 10px;
                      height: 440px;
                      object-fit: cover;
                      margin-top: 15px;
                    "
                  />
                </router-link>
              </div>
              <div class="col-md-8">
                <div class="row mt-md-0 mt-4">
                  <div
                    class="col-md-4 col-sm-6 col-xs-6 col-6 wide_space"
                    v-for="(a, i) in Besttvshows.slice(8, 14)"
                    :key="i"
                  >
                    <router-link :to="'/series-details/' + a.id">
                      <div class="card cursor-pointer best-series-card">
                        <img
                          v-lazy="a.image"
                          class="card-img-top cust-img-top"
                          alt
                        />
                      </div>
                      <div class="mt-1 movie-text">
                        {{ getYear(a.movireleasedate) }},
                        <span v-for="(aa, k) in a.categories" :key="k">
                          {{ aa }}
                          <span v-if="a.categories.length - 1 !== k">,</span>
                        </span>
                      </div>
                      <div class="font-weight-bold">{{ a.post_title }}</div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--<div class="space-top"></div>-->

          <!--Featured Series-->
          <div class="row">
            <div class="col-md-6">
              <h3 class="text-atvyellow font-weight-bold">Featured Series</h3>
              <p>The newest series to arrive on Aukiss!</p>
            </div>
            <div class="col-md-6">
              <!--<h5 class="mr-md-3 text-md-right text-muted font-weight-bold">View all</h5>-->
            </div>
          </div>
          <div class="featured-series">
            <div class="row">
              <div
                class="col-md-6 col-sm-6 col-xs-6 col-6"
                v-for="(a, i) in FeaturedSeries.slice(0, 2)"
                :key="i"
              >
                <router-link
                  :to="'/series-details/' + a.id"
                  @click.native.prevent="
                    handleNavigation(a.id, 'seriesdetails')
                  "
                >
                  <img
                    v-lazy="a.image"
                    class="img-fluid w-100 featured-series-img"
                    style="
                      height: 300px;
                      object-fit: cover;
                      border-radius: 10px;
                    "
                    alt
                  />
                </router-link>
              </div>
            </div>

            <div class="row" v-if="FeaturedSeries.length > 2">
              <div
                class="col-md-6 col-sm-6 col-xs-6 col-6"
                v-for="(a, i) in FeaturedSeries.slice(2, 4)"
                :key="i"
              >
                <router-link
                  :to="'/series-details/' + a.id"
                  @click.native.prevent="
                    handleNavigation(a.id, 'seriesdetails')
                  "
                >
                  <img
                    v-lazy="a.image"
                    class="img-fluid w-100 featured-series-img"
                    style="
                      height: 300px;
                      object-fit: cover;
                      border-radius: 10px;
                    "
                    alt
                  />
                </router-link>
              </div>
            </div>
          </div>
          <div class="space-top"></div>

          <!--parallax section-->
          <div
            class="fixed-bg"
            :style="{
              'background-image':
                'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url(' +
                (MiddleHeader[0] || {}).image +
                ')',
            }"
          >
            <div class="fixed-bg-sub mb-3">
              <h2 class="font-weight-bold mb-0">
                {{ (MiddleHeader[0] || {}).post_title }}
              </h2>
              <div class="caption_text font-weight-bold">
                <span v-if="(MiddleHeader[0] || {}).movie_time_duration"
                  >{{ (HeaderMovies[0] || {}).movie_time_duration }} |</span
                >
                <span
                  v-for="(aa, k) in (MiddleHeader[0] || {}).categories"
                  :key="k"
                >
                  {{ aa }}
                  <span
                    v-if="
                      ((MiddleHeader[0] || {}).categories || []).length - 1 !==
                      k
                    "
                    >,</span
                  >
                </span>
              </div>
              <p style="font-size: 15px" class="font-weight-bold">
                {{
                  ((MiddleHeader[0] || {}).post_excerpt || []).length > 130
                    ? (MiddleHeader[0] || {}).post_excerpt.substring(0, 130) +
                      "..."
                    : (MiddleHeader[0] || {}).post_excerpt
                }}
              </p>
              <router-link
                :to="'/movie-details/' + (MiddleHeader[0] || {}).id"
                class="btn btn-atvyellow"
                @click.native.prevent="
                  handleNavigation((MiddleHeader[0] || {}).id, 'moviedetails')
                "
                >Details</router-link
              >
            </div>
          </div>

          <!--Action -->
          <div class="row" v-if="Actions.length > 0">
            <div class="col-md-6">
              <h3 class="text-atvyellow font-weight-bold">Action</h3>
              <p>
                See all the kicks, punches and explosions the Diaspora has to
                offer!
              </p>
            </div>
            <div class="col-md-6">
              <!--<h5 class="mr-md-3 text-md-right text-muted font-weight-bold">View all</h5>-->
            </div>
          </div>
          <div class="row owl-carousel pl-3">
            <div class="mr-md-5 mr-3" v-for="(a, i) in Actions" :key="i">
              <router-link
                :to="'/movie-details/' + a.id"
                @click.native.prevent="handleNavigation(a.id, 'moviedetails')"
              >
                <div class="card cursor-pointer">
                  <img v-lazy="a.image" class="card-img-top" alt />
                </div>
                <div class="mt-1 movie-text">
                  <!-- <span v-if="a.movireleasedate">{{ getYear(a.movireleasedate) }},&nbsp;</span>
                  <span v-for="(aa, k) in a.categories.slice(0, 1)" :key="k">
                    {{ aa
                    }}
                    <span
                      v-if="a.categories.slice(0, 1).length - 1 !== k"
                    >,</span>
                  </span>
                  <span v-if="a.categories.length > 1">...</span>-->
                </div>
                <div class="font-weight-bold">{{ a.post_title }}</div>
              </router-link>
            </div>
          </div>
          <div class="space-top"></div>

          <!--Urban Classics-->
          <div class="row" v-if="UrbanClassics.length > 0">
            <div class="col-md-6">
              <h3 class="text-atvyellow font-weight-bold">Urban Classics</h3>
              <p>We keep the humor and drama coming Urban Classics.</p>
            </div>
            <div class="col-md-6">
              <!--<h5 class="mr-md-3 text-md-right text-muted font-weight-bold">View all</h5>-->
            </div>
          </div>
          <div class="row owl-carousel pl-3">
            <div class="mr-md-5 mr-3" v-for="(a, i) in UrbanClassics" :key="i">
              <router-link
                :to="'/movie-details/' + a.id"
                @click.native.prevent="handleNavigation(a.id, 'moviedetails')"
              >
                <div class="card cursor-pointer">
                  <img v-lazy="a.image" class="card-img-top" alt />
                </div>
                <div class="mt-1 movie-text">
                  <!-- <span v-if="a.movireleasedate">{{ getYear(a.movireleasedate) }},&nbsp;</span>
                  <span v-for="(aa, k) in a.categories.slice(0, 1)" :key="k">
                    {{ aa
                    }}
                    <span
                      v-if="a.categories.slice(0, 1).length - 1 !== k"
                    >,</span>
                  </span>
                  <span v-if="a.categories.length > 1">...</span>-->
                </div>
                <div class="font-weight-bold">{{ a.post_title }}</div>
              </router-link>
            </div>
          </div>
          <div class="space-top"></div>

          <!--Kumawood-->
          <!-- <div class="row">
            <div class="col-md-6">
              <h3 class="text-atvyellow font-weight-bold">Kumawood</h3>
              <p>We keep the humor and drama coming Kumawood</p>
            </div>
            <div class="col-md-6">
            
            </div>
          </div> -->
          <!-- <div class="row owl-carousel pl-3">
            <div class="mr-md-5 mr-3" v-for="(a, i) in KumaWood" :key="i">
              <router-link :to="'/movie-details/' + a.id">
                <div class="card cursor-pointer">
                  <img v-lazy="a.image" class="card-img-top" alt />
                </div>
                <div class="mt-1 movie-text">
                
                </div>
                <div class="font-weight-bold">{{ a.post_title }}</div>
              </router-link>
            </div>
          </div>
          <div class="space-top"></div> -->

          <!--Drama-->
          <div class="row">
            <div class="col-md-6">
              <h3 class="text-atvyellow font-weight-bold">Drama</h3>
              <p>We keep the humor and drama coming</p>
            </div>
            <div class="col-md-6">
              <!--<h5 class="mr-md-3 text-md-right text-muted font-weight-bold">View all</h5>-->
            </div>
          </div>
          <div class="row owl-carousel pl-3">
            <div class="mr-md-5 mr-3" v-for="(a, i) in Drama" :key="i">
              <router-link
                :to="'/movie-details/' + a.id"
                @click.native.prevent="handleNavigation(a.id, 'moviedetails')"
              >
                <div class="card cursor-pointer">
                  <img v-lazy="a.image" class="card-img-top" alt />
                </div>
                <div class="mt-1 movie-text"></div>
                <div class="font-weight-bold">{{ a.post_title }}</div>
              </router-link>
            </div>
          </div>
          <div class="space-top"></div>

          <!--Comedy-->
          <div class="row" v-if="Comedies.length > 0">
            <div class="col-md-6">
              <h3 class="text-atvyellow font-weight-bold">Comedy</h3>
              <p>Big laughs for your big screen.</p>
            </div>
            <div class="col-md-6">
              <!--<h5 class="mr-md-3 text-md-right text-muted font-weight-bold">View all</h5>-->
            </div>
          </div>
          <div class="row owl-carousel pl-3">
            <div class="mr-md-5 mr-3" v-for="(a, i) in Comedies" :key="i">
              <router-link
                :to="'/movie-details/' + a.id"
                @click.native.prevent="handleNavigation(a.id, 'moviedetails')"
              >
                <div class="card cursor-pointer">
                  <img v-lazy="a.image" class="card-img-top" alt />
                </div>
                <div class="mt-1 movie-text">
                  <!-- <span v-if="a.movireleasedate">{{ getYear(a.movireleasedate) }},&nbsp;</span>
                  <span v-for="(aa, k) in a.categories.slice(0, 1)" :key="k">
                    {{ aa
                    }}
                    <span
                      v-if="a.categories.slice(0, 1).length - 1 !== k"
                    >,</span>
                  </span>
                  <span v-if="a.categories.length > 1">...</span>-->
                </div>
                <div class="font-weight-bold">{{ a.post_title }}</div>
              </router-link>
            </div>
          </div>
          <div class="space-top"></div>
        </div>
      </div>
      <footernav></footernav>
    </div>
    <div
      class="modal fade"
      id="movieModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="text-center">
              <h3 class="text-orange mt-2 font-weight-bold">
                {{ movie_title }}
              </h3>
            </div>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="stopPlayer()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <video poster="capture.jpg" id="player" playsinline controls>
              <source src type="video/mp4" />
              <source src type="video/webm" />
            </video>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-orange button"
              data-dismiss="modal"
              aria-label="Close"
              @click="stopPlayer()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="m3u8Modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="text-center">
              <h3 class="text-orange mt-2 font-weight-bold">
                {{ movie_title }}
              </h3>
            </div>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="stopPlayer()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <video-player
              ref="videoPlayer"
              class="vjs-custom-skin"
              :options="playerOptions"
              @ready="onPlayerReady($event)"
            ></video-player>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-orange button"
              data-dismiss="modal"
              aria-label="Close"
              @click="stopPlayer()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import "vue-loading-overlay/dist/vue-loading.css";
import Plyr from "plyr";
import VideoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "Home",
  data() {
    return {
      settings: this.search(),
      isLoading: false,
      fullPage: true,
      height: 128,
      width: 128,
      movie_title: {},
      movie_player: "",
      poster_image: "",
      userInfo: this.$store.state.userInfo,
      subscription: {},
      NewlyAddedContent: [],
      NewestMovies: [],
      Besttvshows: [],
      FeaturedSeries: [],
      HeaderMovies: [],
      KumaWood: [],
      Documentaries: [],
      UrbanClassics: [],
      Drama: [],
      Comedies: [],
      Actions: [],
      Episodes: [],
      NewestEpisodes: [],
      MiddleHeader: [],
      player: "",
      playing: "",
      players: "",
      m3u8Type: "m3u8",
      initialized: false,
      currentTech: "",
      m3u8_video_url: "",
      playerOptions: {
        autoplay: true,
        controls: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
        // poster: 'https://surmon-china.github.io/vue-quill-editor/static/images/surmon-5.jpg'
      },
      videoSrc: "",
      details: {
        id: this.$store.state.userInfo.id,
        city: "",
        continentCode: "",
        continentName: "",
        countryCode: "",
        countryName: "",
        ipAddress: "",
        stateProv: "",
      },
    };
  },
  methods: {
    search: function () {
      var me = this;
      return {
        valueField: "type",
        labelField: "post_title",
        searchField: "post_title",
        create: false,
        onItemAdd: function (value, $item) {
          let data1 = value.split("/")[0].replace(/\s/g, "");
          let data2 = value.split("/")[1].replace(/\s/g, "");
          console.log(data1, data2);
          if (data1 == "homepagemovie") {
            me.$router.push("/movie-details/" + data2);
          } else if (data1 == "homepagetvshow") {
            me.$router.push("/series-details/" + data2);
          } else if (data1 == "allcelebrity") {
            me.$router.push("/celebrity-profile/" + data2);
          }
        },
        render: {
          option: function (item, escape) {
            let mydata = "";
            if (item.component === "homepagemovie") {
              mydata =
                "<div>" +
                '<span class="selectize_title">' +
                '<span class="selectize_name"><img class="selectize_img" src="' +
                escape(item.image) +
                '" alt="">' +
                escape(item.post_title) +
                "</span>" +
                "</span>" +
                '<span class="selectize_description">' +
                escape(item.post_content) +
                "</span>" +
                '<ul class="selectize_meta">' +
                (item.movireleasedate
                  ? '<li class="language">' +
                    escape(new Date(item.movireleasedate).getFullYear()) +
                    "</li>"
                  : "") +
                "</ul>" +
                "</div>";
            } else if (item.component === "homepagetvshow") {
              mydata =
                "<div>" +
                '<span class="selectize_title">' +
                '<span class="selectize_name"><img class="selectize_img" src="' +
                escape(item.image) +
                '" alt="">' +
                escape(item.post_title) +
                "</span>" +
                "</span>" +
                '<span class="selectize_description">' +
                escape(item.post_content) +
                "</span>" +
                '<ul class="selectize_meta">' +
                (item.movireleasedate
                  ? '<li class="language">' +
                    escape(new Date(item.movireleasedate).getFullYear()) +
                    "</li>"
                  : "") +
                "</ul>" +
                "</div>";
            } else if (item.component === "allcelebrity") {
              mydata =
                "<div>" +
                '<span class="selectize_title">' +
                '<span class="selectize_name"><img class="celebrity_img" src="' +
                escape(item.photo) +
                '" alt="">' +
                escape(item.name) +
                "</span>" +
                "</span>" +
                (item.person_info
                  ? '<span class="selectize_description">' +
                    escape(item.person_info) +
                    "</span>"
                  : "") +
                "</div>";
            }
            return mydata;
          },
        },
        load: function (query, callback) {
          if (!query.length) return callback();
          $.ajax({
            url: " https://api.aukiss.tv/api/searchwebhomemovie",
            type: "POST",
            data: {
              title: encodeURIComponent(query),
            },
            error: function () {
              callback();
            },
            success: function (res) {
              let mydata = res.data;
              console.log(res);
              if (mydata.length > 0) {
                mydata.forEach((e) => {
                  e.type = res.message.replace(/\s/g, "") + "/" + e.id;
                  e.component = res.message.replace(/\s/g, "");
                  if (e.component == "allcelebrity") {
                    e.image = e.photo;
                    e.post_title = e.name;
                    e.post_content = e.person_info;
                  }
                });
                callback(mydata);
              }
            },
          });
        },
      };
    },
    getYear(d) {
      if (d) {
        return moment(new Date(d)).format("YYYY");
      }
    },
    getAllMovies() {
      this.isLoading = true;
      axios
        .get("https://api.aukiss.tv/api/getweballhomemovies")
        .then((res) => {
          this.NewlyAddedContent = res.data.NewlyAddedContent;
          this.NewestMovies = res.data.NewestMovies;
          this.Besttvshows = res.data.Besttvshows;
          this.FeaturedSeries = res.data.FeaturedSeries;
          // if (this.FeaturedSeries.length > 0) {
          //     this.Episodes = this.FeaturedSeries[0].episodes;
          // }
          this.HeaderMovies = res.data.HeaderMovies;
          this.KumaWood = res.data.KumaWood;
          this.Documentaries = res.data.Documentaries;
          this.UrbanClassics = res.data.UrbanClassics;
          this.Comedies = res.data.Comedies;
          this.NewestEpisodes = res.data.NewestEpisodes;
          this.Actions = res.data.Actions;
          this.MiddleHeader = res.data.MiddleHeader;
          this.Drama = res.data.Drama;

          this.$nextTick(() => {
            exeCarousel();
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    onPlayerReady(player) {
      console.log("player ready!", player);
      this.player = player;
      this.playVideo(this.videoSrc);
      this.player.play();
    },
    playVideo: function (source) {
      console.log(source);
      const video = {
        withCredentials: false,
        type: "application/x-mpegurl",
        src: source,
      };
      //this.player.dispose();
      this.player.reset(); // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video);

      //this.player.load();
      this.player.play();
    },
    onTimeupdate(e) {
      console.log("currentTime", e.cache_.currentTime);
    },

    triggerPlayer(videoUrl, type) {
      this.videoSrc = videoUrl;
      this.player = this.$refs.videoPlayer;
      var data = 1;

      if (type !== "m3u8") {
        if (data.length < 1) {
          $("#oldplayer").remove();

          data = data + 1;
        }
        console.log("data", data);

        this.$nextTick(() => {
          this.movie_player = new Plyr("#player", {
            debug: false,
            autoplay: true,
            settings: ["speed"],
          });
          if (type == "youtube") {
            this.movie_player.source = {
              type: "video",
              sources: [
                {
                  src: videoUrl ? videoUrl : "",
                  provider: "youtube",
                },
              ],
            };
          } else {
            this.movie_player.source = {
              type: "video",
              title: "Example title",
              sources: [
                {
                  src: videoUrl ? videoUrl : "",
                  type: "video/mp4",
                  size: 720,
                },
                {
                  src: videoUrl ? videoUrl : "",
                  type: "video/webm",
                  size: 1080,
                },
              ],
              poster: this.poster_image ? this.poster_image : "",
            };
          }
        });
      } else {
        if (this.player) {
          this.player = this.$refs.videoPlayer.player;
          this.player.reset();
          this.playVideo(videoUrl);
        }
      }
    },
    showVideo(videoUrl, type) {
      this.m3u8Type = type;

      if (type === "m3u8") {
        openModal("#m3u8Modal");
        this.movie_player = "";
        if (this.movie_player) {
          this.movie_player.stop();
          this.movie_player.destroy();
          //this.triggerPlayer(videoUrl, type);
          return;
        }
      } else {
        openModal("#movieModal");
        if (this.movie_player) {
          this.movie_player.destroy();
        }
      }
      this.triggerPlayer(videoUrl, type);
    },
    stopPlayer() {
      if (this.movie_player) {
        this.movie_player.stop();
      }
    },

    handleNavigation(ids, type) {
      if (type == "moviedetails") {
        axios
          .post("https://api.aukiss.tv/api/webuserSubplan", {
            user_id: this.userInfo.id,
          })
          .then((res) => {
            if (res.data.data !== null) {
              if (
                ((res.data.data || {}).user || {}).role_id == 2 ||
                ((res.data.data || {}).user || {}).role_id == 3 ||
                ((res.data.data || {}).user || {}).role_id == 4 ||
                ((res.data.data || {}).user || {}).role_id == 5
              ) {
                this.$router.push("/movie-details/" + ids);
              } else {
                this.subscription = res.data.data;
                if (
                  (this.subscription || {}).expired_at ||
                  this.subscription == null
                ) {
                  if (this.checkDate()) {
                    this.$router.push("/subscription/expired");
                  } else {
                    this.$router.push("/movie-details/" + ids);
                  }
                } else {
                  //
                  this.$router.push("/subscription");
                }
              }
            } else {
              this.$router.push("/subscription");
            }
          });
      } else if (type == "seriesdetails") {
        axios
          .post("https://api.aukiss.tv/api/webuserSubplan", {
            user_id: this.userInfo.id,
          })
          .then((res) => {
            if (res.data.data !== null) {
              if (
                ((res.data.data || {}).user || {}).role_id == 2 ||
                ((res.data.data || {}).user || {}).role_id == 3 ||
                ((res.data.data || {}).user || {}).role_id == 4 ||
                ((res.data.data || {}).user || {}).role_id == 5
              ) {
                this.$router.push("/series-details/" + ids);
              } else {
                this.subscription = res.data.data;
                if (
                  (this.subscription || {}).expired_at ||
                  this.subscription == null
                ) {
                  if (this.checkDate()) {
                    this.$router.push("/subscription/expired");
                  } else {
                    this.$router.push("/series-details/" + ids);
                  }
                } else {
                  //
                  this.$router.push("/subscription");
                }
              }
            } else {
              this.$router.push("/subscription");
            }
          });
      }
    },

    saveInfo() {
      this.isLoading = true;

      axios
        .post("https://api.aukiss.tv/api/locationupdate", this.details)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getlocation() {
      this.isLoading = true;
      axios
        .get("https://api.db-ip.com/v2/free/self")
        .then((res) => {
          this.details.city = res.data.city;
          this.details.continentCode = res.data.continentCode;
          this.details.continentName = res.data.continentName;
          this.details.countryCode = res.data.countryCode;
          this.details.countryName = res.data.countryName;
          this.details.ipAddress = res.data.ipAddress;
          this.details.stateProv = res.data.stateProv;

          this.saveInfo();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    checkExpiration() {
      axios
        .post("https://api.aukiss.tv/api/webuserSubplan", {
          user_id: this.userInfo.id,
        })
        .then((res) => {
          // console.log("here")
          // console.log("subscription", res.data.data);
          if (res.data.data !== null) {
            // if (
            //   ((res.data.data || {}).user || {}).role_id == 2 ||
            //   ((res.data.data || {}).user || {}).role_id == 3 ||
            //   ((res.data.data || {}).user || {}).role_id == 4 ||
            //   ((res.data.data || {}).user || {}).role_id == 5
            // ) {

            //} else {
            this.subscription = res.data.data;
            if (
              (this.subscription || {}).expired_at ||
              this.subscription == null
            ) {
              if (this.checkDate()) {
                this.$router.push("/subscription/expired");
              }
            } else {
              this.$router.push("/subscription");
            }
            // }
          } else {
            this.$router.push("/subscription");
            // if (this.userInfo.first_name.toLowerCase() == "nana") {
            // } else {

            // }
          }
        });
    },

    checkDate() {
      var date = moment(this.subscription.expired_at);
      var now = moment();
      if (now > date) {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {
    topnav: () => import("@/components/darktopNav"),
    footernav: () => import("@/components/footer"),
    Loading: () => import("vue-loading-overlay"),
    Selectize: () => import("vue2-selectize"),
    VideoPlayer,
  },
  created() {
    document.title = "Home || Aukiss TV";
    this.$loadScript("/assets/js/owl.carousel.min.js")
      .then(() => {
        // this.init();
      })
      .catch(() => {});
    this.$loadScript(
      "https://cdn.jsdelivr.net/npm/moment@2.27.0/min/moment.min.js"
    ).then(() => {});
    this.getAllMovies();
    this.checkExpiration();
    this.getlocation();
    var elements = document.getElementsByClassName(
      "index-page sidebar-collapse"
    );
    for (var i = 0; i < elements.length; i++) {
      elements[i].removeAttribute("style");
    }
  },
};
</script>

<style scoped>
@import url("../../public/assets/css/owl.carousel.css");
@import url("../../public/assets/css/owl.theme.css");
@import url("../../public/assets/css/owl.transitions.css");

.space-top {
  margin-top: 3.5rem;
}

.card-img-top[lazy="loading"] {
  object-fit: cover !important;
}

.fixed-bg {
  background-image: url("../../public/assets/img/parallax.png");
  height: 390px;
  /* background-attachment: fixed; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.fixed-bg-sub {
  position: absolute;
  color: #fff;
  width: 55%;
  padding: 5px 10px;
  text-align: left;
  margin-left: 2rem;
  /* top: 1px; */
  margin-top: 100px;
}

@media only screen {
  .parallax {
    /* The image used */
    background-image: url("../../public/assets/img/parallax.png");
    /* Set a specific height */
    height: 350px;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.parallax-caption {
  position: absolute;
  color: #fff;
  /* width: 55%; */
  /* z-index: 8; */
  padding: 5px 10px;
  text-align: left;
  margin-left: 2rem;
  /* top: 1px; */
  margin-top: 100px;
}

.nivo-caption {
  position: absolute;
  top: 60%;
  color: #fff;
  width: 55%;
  z-index: 8;
  padding: 5px 10px;
  text-align: left;
}

.carousel-caption {
  bottom: 40% !important;
  left: 5% !important;
}

.card {
  margin-bottom: 0px !important;
  margin-top: 10px !important;
  border-radius: 10px !important;
}

.best-series-card {
  margin-top: 15px !important;
}

.card-img-top {
  height: 215px !important;
  /*object-fit: cover !important;*/
  border-radius: 10px !important;
}

.cust-img-top {
  height: 215px !important;
  /* object-fit: cover !important; */
}

.newest-episode-card {
  height: 160px !important;
}

@media screen and (max-width: 599px) and (min-width: 300px) {
  .card-img-top {
    height: auto !important;
  }

  .cust-img-top {
    height: 215px !important;
    /* object-fit: cover !important; */
  }

  .featured-series-img {
    height: 115px !important;
  }

  .best-series-first-img {
    height: auto !important;
  }

  .caption_text {
    font-size: 14px;
  }

  .newest-episode-card {
    height: auto !important;
  }

  .nivo-caption {
    top: 0%;
    width: 100%;
  }

  .parallax-caption {
    /*top: 15%;*/
    margin-left: 0px;
    width: 80%;
  }

  .fixed-bg-sub {
    margin-left: 0px;
    width: 80%;
  }

  .slider_image {
    height: 302px !important;
  }
}

.tainer::after {
  /*background: #000000c4;*/
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0) 90%,
    rgba(0, 0, 0, 0)
  ) !important;
}

.tainer {
  border-radius: 5px !important;
}

.category_list li {
  margin-bottom: 1.2rem;
}

.caption_text {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}

@media screen and (min-width: 768px) {
  .d-sm-block-custom {
    display: block !important;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1164px) {
  .slider_image {
    height: 600px !important;
  }
}

@media screen and (max-width: 1164px) and (min-width: 768px) {
  .slider_image {
    height: 400px !important;
  }

  .carousel-caption {
    bottom: 47% !important;
  }

  .parallax-caption {
    /*top: 20%;*/
    width: 80%;
  }

  .fixed-bg-sub {
    width: 80%;
  }
}
</style>
